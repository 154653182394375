import { FC } from 'react';
import ModalWrap from '../common/modal-wrap';
import { CloseCrossBtn } from '../icons/close-cross-btn';
import Button from '../common/button';

import { IConsultation } from '../../store/types';
import { getDate, getMyTimeZone, getTime, getTimeWithTZ } from '../../utils/getTime';

interface IDeleteProps {
  open: boolean;
  close: () => void;
  deleteConsultation: (id: number) => void;
  data: IConsultation | null;
  client?: {
    id: number;
    name: string;
  };
}

export const DeleteConsultationForm: FC<IDeleteProps> = ({
  open,
  data,
  close,
  deleteConsultation,
  client,
}) => {
  if (!data) return <></>;

  const handleDelete = () => {
    if (!data) return;
    deleteConsultation(data.id);
    close();
  };

  return (
    <ModalWrap isShow={open}>
      <div className="think-scroll flex items-start gap-6 w-full max-w-[90%] sm:max-w-[60%] md:max-w-[540px] h-auto rounded-[20px] lg:rounded-[40px] p-5 lg:p-10 bg-white">
        <svg
          className="-mt-1.5 min-w-[40px] hidden md:block"
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 3.23877C10.3359 3.23877 2.5 11.0747 2.5 20.7388C2.5 30.4028 10.3359 38.2388 20 38.2388C29.6641 38.2388 37.5 30.4028 37.5 20.7388C37.5 11.0747 29.6641 3.23877 20 3.23877ZM18.75 12.3013C18.75 12.1294 18.8906 11.9888 19.0625 11.9888H20.9375C21.1094 11.9888 21.25 12.1294 21.25 12.3013V22.9263C21.25 23.0981 21.1094 23.2388 20.9375 23.2388H19.0625C18.8906 23.2388 18.75 23.0981 18.75 22.9263V12.3013ZM20 29.4888C19.5093 29.4788 19.0422 29.2768 18.6987 28.9263C18.3553 28.5757 18.1629 28.1045 18.1629 27.6138C18.1629 27.123 18.3553 26.6518 18.6987 26.3013C19.0422 25.9507 19.5093 25.7488 20 25.7388C20.4907 25.7488 20.9578 25.9507 21.3013 26.3013C21.6447 26.6518 21.8371 27.123 21.8371 27.6138C21.8371 28.1045 21.6447 28.5757 21.3013 28.9263C20.9578 29.2768 20.4907 29.4788 20 29.4888Z"
            fill="#E0E0E8"
          />
        </svg>

        <div className="w-full flex flex-col gap-3.5 lg:gap-4">
          <div className="w-full flex justify-between items-center">
            <svg
              className="-mt-1.5 min-w-[35px] block md:hidden"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 3.23877C10.3359 3.23877 2.5 11.0747 2.5 20.7388C2.5 30.4028 10.3359 38.2388 20 38.2388C29.6641 38.2388 37.5 30.4028 37.5 20.7388C37.5 11.0747 29.6641 3.23877 20 3.23877ZM18.75 12.3013C18.75 12.1294 18.8906 11.9888 19.0625 11.9888H20.9375C21.1094 11.9888 21.25 12.1294 21.25 12.3013V22.9263C21.25 23.0981 21.1094 23.2388 20.9375 23.2388H19.0625C18.8906 23.2388 18.75 23.0981 18.75 22.9263V12.3013ZM20 29.4888C19.5093 29.4788 19.0422 29.2768 18.6987 28.9263C18.3553 28.5757 18.1629 28.1045 18.1629 27.6138C18.1629 27.123 18.3553 26.6518 18.6987 26.3013C19.0422 25.9507 19.5093 25.7488 20 25.7388C20.4907 25.7488 20.9578 25.9507 21.3013 26.3013C21.6447 26.6518 21.8371 27.123 21.8371 27.6138C21.8371 28.1045 21.6447 28.5757 21.3013 28.9263C20.9578 29.2768 20.4907 29.4788 20 29.4888Z"
                fill="#E0E0E8"
              />
            </svg>
            <h2 className="font-comfortaa lg:text-2xl text-[#111026] font-bold">
              Удаление консультации
            </h2>
            <button className="cursor-pointer" onClick={() => close()}>
              <CloseCrossBtn width={20} height={20} />
            </button>
          </div>
          <div className="flex flex-col gap-4">
            <p className="max-w-[331px] font-comfortaa text-sm md:text-base font-semibold">
              Вы действительно хотите удалить консультацию {`«${client?.name}»`} ?
            </p>
            <div className="flex flex-col items-start gap-[8px] text-sm md:text-base">
              <div className="flex gap-[15px]">
                <p className="text-[#878791]">Дата встречи:</p>
                <p className="text-blackBase">{getDate(new Date(data.date))}</p>
              </div>
              <div className="flex gap-[15px]">
                <p className="text-[#878791] min-w-max">Ваше время:</p>
                <p className="text-blackBase">{`${getTime(
                  new Date(data.datetime_start)
                )} - ${getTime(new Date(data.datetime_end))} (${getMyTimeZone()})`}</p>
              </div>
            </div>
          </div>
          <div className="flex gap-6 justify-end mt-6">
            <Button
              className=" max-w-[160px] !text-sm md:!text-base py-[6px] px-2.5"
              type="grey"
              onClick={close}>
              Отмена
            </Button>
            <Button
              className="max-w-[160px] !text-sm md:!text-base py-[6px] px-2.5"
              type="black"
              onClick={handleDelete}>
              Удалить
            </Button>
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};
