import { ConfigProvider, DatePicker, GetProps } from 'antd';
import { FC, useRef, useState } from 'react';
import 'dayjs/locale/ru';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

export const DatePickerCustom: FC<{
  date?: string;
  setDate: (a: any) => void;
  className?: string;
  label?: string;
  required?: boolean;
  disabledDate?: (current: any) => boolean;
  disabled?: boolean;
}> = ({ className, setDate, label, date, required, disabledDate, disabled }) => {
  const [focusedDate, setfocusedDate] = useState(false);
  const dateRef = useRef<any>();

  return (
    <div className="w-full relative flex flex-col gap-[5px]">
      {label && (
        <div
          className="w-full flex items-center justify-between"
          onClick={() => dateRef.current.focus()}>
          <p className="text-xs text-[#585858]">
            {label} {required ? ' *' : ''}
          </p>
        </div>
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#C00000',
            colorLink: '#111026',
          },
          components: {
            DatePicker: {
              activeShadow: 'none',
              hoverBorderColor: '#111026',
              activeBorderColor: '#111026',
            },
          },
        }}>
        <DatePicker
          className={classNames(
            'placeholder-grey w-full h-[40px] lg:h-[48px] p-[10px_15px] md:p-[13px_20px] w-full text-blackBase [&>div>input]:!text-[16px] rounded-[10px] border border-[1px] border-blackBase placeholder:text-[#585858] z-[2] bg-transparent !font-manrope',
            className,
            {
              ['!border-[2px]']: focusedDate,
            }
          )}
          defaultValue={date ? dayjs(date) : undefined}
          value={date ? dayjs(date) : undefined}
          ref={dateRef}
          placeholder="01.01.2000"
          disabled={disabled}
          onChange={(_, val) => {
            setfocusedDate(false);
            setDate(
              typeof val === 'string'
                ? val.split('.').reverse().join('-')
                : val[0].split('.').reverse().join('-')
            );
          }}
          locale={locale}
          format={'DD.MM.YYYY'}
          open={focusedDate}
          onFocus={() => setfocusedDate(true)}
          onOpenChange={(open) => setfocusedDate(open)}
          disabledDate={disabledDate}
          suffixIcon={
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.5 9.46354H17.5M14.1667 2.79688V6.13021M5.83333 2.79688V6.13021M14.1667 4.46354H5.83333C4.94928 4.46354 4.10143 4.81473 3.47631 5.43985C2.85119 6.06497 2.5 6.91282 2.5 7.79687V15.0885C2.5 15.9726 2.85119 16.8204 3.47631 17.4456C4.10143 18.0707 4.94928 18.4219 5.83333 18.4219H14.1667C15.0507 18.4219 15.8986 18.0707 16.5237 17.4456C17.1488 16.8204 17.5 15.9726 17.5 15.0885V7.79687C17.5 6.91282 17.1488 6.06497 16.5237 5.43985C15.8986 4.81473 15.0507 4.46354 14.1667 4.46354Z"
                stroke="#111026"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
      </ConfigProvider>
    </div>
  );
};
