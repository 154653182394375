import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as faceapi from 'face-api.js';

export function handleJanusData(videoRef, fitModeRef, prevPositionRef) {
  return (e) => {
    const { type } = e.detail;
    const video = videoRef.current;
    if (!video) return;

    if (type === 'video-off' && fitModeRef.current) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.objectFit = fitModeRef.current;
      canvas.style.objectPosition = `${100-prevPositionRef.current.x}% ${prevPositionRef.current.y}%`;
      canvas.style.aspectRatio = `${video.videoWidth} / ${video.videoHeight}`;
      canvas.style.zIndex = '10';
      canvas.style.filter = 'blur(10px)';
      canvas.id = 'canvas-blur';

      ctx.save();
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      ctx.restore();

      const label = document.createElement('div');
      label.textContent = 'Пользователь отключил видео';
      label.style.position = 'absolute';
      label.style.top = '50%';
      label.style.left = '50%';
      label.style.transform = 'translate(-50%, -50%)';
      label.style.background = 'rgba(0, 0, 0, 0.7)';
      label.style.color = '#fff';
      label.style.padding = '12px 24px';
      label.style.borderRadius = '16px';
      label.style.zIndex = '11';
      label.id = 'label-blur';

      video.parentNode.appendChild(canvas);
      video.parentNode.appendChild(label);
      video.style.visibility = 'hidden';
    } else if (type === 'video-on') {
      const canvas = document.getElementById('canvas-blur');
      const label = document.getElementById('label-blur');
      if (canvas) canvas.remove();
      if (label) label.remove();
      video.style.visibility = 'visible';
    }
  };
}

export function RemoteVideo({ fn, className, fitMode, onFitModeChange }) {
  const videoRef = useRef();
  const fitModeRef = useRef(fitMode);
  const [objectPosition, setObjectPosition] = useState('50% 50%');
  const prevPositionRef = useRef({ x: 50, y: 50 });

  useEffect(() => {
    fitModeRef.current = fitMode;
    videoRef?.current?.setAttribute('playsinline', '');
  }, [fitMode]);

  useEffect(() => {
    fn(videoRef?.current);
    
    const janusHandler = handleJanusData(videoRef, fitModeRef, prevPositionRef);
    document.addEventListener('janusDataMessage', janusHandler);

    if (fitMode !== 'cover') return;

    let intervalId;

    const loadAndTrack = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');

      const updateFacePosition = async () => {
        const video = videoRef.current;

        if (
          !video ||
          video.readyState < 2 ||
          video.videoWidth === 0 ||
          video.videoHeight === 0
        ) return;

        const result = await faceapi.detectSingleFace(
          video,
          new faceapi.TinyFaceDetectorOptions()
        );
        if (result && fitMode === 'cover') {
          const { x, y, width, height } = result.box;
          const xCenter = x + width / 2;
          const yCenter = y + height / 2;
          const xPercent = (xCenter / video.videoWidth) * 100;
          const yPercent = (yCenter / video.videoHeight) * 100;
          const dx = Math.abs(xPercent - prevPositionRef.current.x);
          const dy = Math.abs(yPercent - prevPositionRef.current.y);
          if (dx > 10 || dy > 5) {
            setObjectPosition(`${xPercent}% ${yPercent}%`);
            prevPositionRef.current = { x: xPercent, y: yPercent };
          }
        }
      };

      intervalId = setInterval(updateFacePosition, 1500);
    };

    loadAndTrack();

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('janusDataMessage', janusHandler);
    };
  }, [fn, fitMode]);

  return (
    <div className={classNames('relative w-full h-full', className)}>
      <div className="w-ful h-full z-10 absolute" />
      <video
        id="videoElement"
        className={classNames(
          'w-full h-full bg-black hide-video-controls transition-all duration-500 ease-in-out will-change-[object-fit,object-position]',
          fitMode === 'cover' ? 'object-cover' : 'object-contain'
        )}
        ref={videoRef}
        controls={false}
        autoPlay
        playsInline
        style={{ objectPosition }}
      /> 
      <button
        onClick={() =>
          onFitModeChange?.(fitMode === 'cover' ? 'contain' : 'cover')
        }
        className="absolute top-2 left-2 z-20 bg-black/60 text-white text-xs px-3 py-1 rounded"
      >
        <span className="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
            {fitMode === 'cover' ? (
              <path d="M6 16h2v2h2v2H6v-4zm8 0h4v4h-4v-2h2v-2zm2-8h-2V6h-2V4h4v4zM6 4h4v2H8v2H6V4z" />
            ) : (
              <path d="M7 14h2v2h2v2H7v-4zm8 0h2v4h-4v-2h2v-2zM7 6h4v2H9v2H7V6zm10 0v4h-2V8h-2V6h4z" />
            )}
          </svg>
          <span>{fitMode === 'cover' ? 'Во весь экран' : 'Без увеличения'}</span>
        </span>
      </button>
    </div>
  );
}

RemoteVideo.propTypes = {
  fn: PropTypes.func.isRequired,
  className: PropTypes.string,
  fitMode: PropTypes.oneOf(['cover', 'contain']),
  onFitModeChange: PropTypes.func,
};
