import React, { useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { VideoJSPlayer } from '../../utils/videojs';

export const VideoJS = ({ options, onReady }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const lastVideoTimeRef = React.useRef(0);
  const idleStartRef = React.useRef(null);
  const [connectionQuality, setConnectionQuality] = React.useState('excellent');
  let frameInterval;


  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        const videoJsPlayer = new VideoJSPlayer(videoElement, player, options);
        onReady && onReady(videoJsPlayer);
      }));

      const retryPlayback = () => {
        if (player && options.sources?.length) {
          player.src(options.sources);
          player.play().catch((e) => console.warn('Retry play failed', e));
        }
      };

      setInterval(() => {
        if (connectionQuality === 'disconnected') {
          console.log('Attempting resync...');
          retryPlayback();
        }
      }, 5000);

      const canvas = document.getElementById('video-fallback-canvas');
      const updateFrame = () => {
        const video = player.el().querySelector('video');
        const currentTime = video.currentTime;
        if (
          video &&
          canvas &&
          video.readyState >= 2 && // HAVE_CURRENT_DATA
          video.videoWidth > 0 &&
          video.videoHeight > 0
        ) {
          const timeDelta = currentTime === lastVideoTimeRef.current
            ? (Date.now() - (idleStartRef.current || Date.now()))
            : 0;

          if (currentTime === lastVideoTimeRef.current) {
            if (!idleStartRef.current) {
              idleStartRef.current = Date.now();
            }

            if (timeDelta > 5000) {
              setConnectionQuality('disconnected');
            } else if (timeDelta > 3000) {
              setConnectionQuality('unstable');
            } else if (timeDelta > 1000) {
              setConnectionQuality('good');
            }
            return;
          } else {
            idleStartRef.current = null;
            setConnectionQuality('excellent');
          }
          lastVideoTimeRef.current = currentTime;

          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.style.filter = 'blur(4px)';
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          }
        }
      };
      
      frameInterval = setInterval(updateFrame, 1000);
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, onReady]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
      clearInterval(frameInterval);
    };
  }, [playerRef]);

  return (
    <div className="h-full relative" data-vjs-player>
      <div
        ref={videoRef}
        className="h-full [&>video-js]:!h-full [&>video-js]:bg-grey1 [&>video-js]:!pt-0"
      />
    </div>
  );
};
