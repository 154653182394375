import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LogoIcon } from '../icons/logo';
import { LANDING } from '../../configs/api/api.config';
import Button from '../common/button';
import ModalWrap from '../common/modal-wrap';
import { CloseCrossBtn } from '../icons/close-cross-btn';
import { useLazyGetConsultationByCodeQuery } from '../../services/users.api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UnicornReview } from './unicorn-review';
import classNames from 'classnames';
import { TextArea } from '../common/textarea';
import AdBanner from "../common/banner/adBanner";


export const ClientEnd = () => {
  const [getConsultations, { data: consultations }] = useLazyGetConsultationByCodeQuery();
  const params = useParams();
  const roomName = params.id;
  const successMess = params.success;
  const [hoverUnicorn, setHoverUnicorn] = useState(0);
  const [activeUnicorn, setActiveUnicorn] = useState(0);
  const [hoverUnicornQuality, setHoverUnicornQuality] = useState(0);
  const [activeUnicornQuality, setActiveUnicornQuality] = useState(0);
  const [note, setNote] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSended, setisSended] = useState(!!successMess);
  const navigate = useNavigate();
  useEffect(() => {
    getConsultations(String(roomName));
  }, []);

  const handleSaveReview = async () => {
    try {
      const data = {
        video_score: activeUnicornQuality,
        consultation_score: activeUnicorn,
        note: note,
        consultation: consultations ? `/api/v1/consultations/${consultations.id}` : '',
        author: null,
        client: consultations ? consultations.client_entity : null,
      };
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/reviews`, data, {});
      if (res.status >= 200 && res.status < 300) {
        setIsShowModal(true);
        setActiveUnicorn(0);
        setActiveUnicornQuality(0);
        setIsBlocked(true);
        setisSended(true);
        setNote('');
        navigate('success');
      }
    } catch (error) {
      toast.error('Произошла ошибка');
      console.log(error);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      <div className="flex flex-col items-center justify-center w-full md:max-w-[350px] h-full order-last md:order-first">
        <div className="w-full flex flex-col items-center gap-2.5 pt-[10px]">
          {/* <p className="font-comfortaa text-lg font-bold text-[#585858]">Реклама</p> */}
          <AdBanner />
          {/* <div className="flex w-full max-w-[240px] h-[400px] items-center justify-center bg-[#FFFFFF] rounded-[20px] lg:rounded-[30px]" /> */}
        </div>
      </div>
      <div className="w-full">
        <div className="w-full h-headerHeightMobile lg:h-headerHeight bg-white shadow-shadowHeader">
          <div className="w-full max-w-[1280px] h-full py-[8px] px-[15px] lg:py-2 mx-auto">
            <div className="h-full flex items-center justify-between">
              <div className="flex flex-col">
                <div className="text-xs md:text-base text-black font-bold leading-3 md:leading-4 mb-[3px] md:mb-[7px]">
                  BETA
                </div>
                <Link to={LANDING}>
                  <LogoIcon className="w-[80px] h-[15px] lg:w-[155px] lg:h-[30px]" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('w-full py-[20px] sm:py-[30px] lg:py-6', {
            'flex flex-col items-center justify-center h-[85%]': isSended,
          })}>
          <h2 className="font-comfortaa font-bold text-xl lg:text-3xl text-center text-blackBlue mb-2.5">
            {!isSended ? 'Видео-конференция завершилась' : 'Спасибо за отзыв'}
          </h2>
          <p className="text-greyBase text-sm lg:text-base text-center mb-4 lg:mb-6">
            ZiNGiZi каждый день старается стать лучше для своих пользователей.
          </p>
          {!isSended && (
            <div className="max-w-[420px] mx-auto bg-white rounded-[15px] lg:rounded-[30px] p-5 shadow-formFeedback sm:mb-8">
              <p className="font-comfortaa font-bold text-sm lg:text-lg text-center text-blackBase mb-3.5">
                Оцените работу психолога
              </p>
              <div className="flex justify-center mb-5 lg:mb-6">
                <UnicornReview
                  hoverUnicorn={hoverUnicorn}
                  unicornActive={activeUnicorn}
                  setHoverUnicorn={setHoverUnicorn}
                  setUnicornActive={setActiveUnicorn}
                />
              </div>
              <p className="font-comfortaa font-bold text-sm lg:text-lg text-center text-blackBase mb-3.5">
                Оцените качество связи ZiNGiZi
              </p>
              <div className="flex justify-center mb-5 lg:mb-6">
                <UnicornReview
                  hoverUnicorn={hoverUnicornQuality}
                  unicornActive={activeUnicornQuality}
                  setHoverUnicorn={setHoverUnicornQuality}
                  setUnicornActive={setActiveUnicornQuality}
                />
              </div>
              <div className="flex flex-col">
                <TextArea
                  value={note}
                  onChange={setNote}
                  placeholder="Напишите о качестве работы сервиса и психолога"
                  label="Дополнительный комментарий"
                />
                <Button
                  disabled={(activeUnicorn === 0 && activeUnicornQuality === 0) || isBlocked}
                  className="w-full self-center"
                  onClick={() => handleSaveReview()}>
                  Отправить
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalWrap isShow={isShowModal}>
        <div className="max-w-full w-[500px] lg:min-w-[500px] bg-white rounded-[40px] shadow-shadowPopup p-[30px] lg:p-10">
          <div className="flex gap-[20px] justify-between items-center mb-[20px] lg:mb-[40px]">
            <h2 className="font-comfortaa font-bold text-blackBase lg:text-2xl">
              Спасибо за отзыв
            </h2>
            <div onClick={() => setIsShowModal(false)}>
              <CloseCrossBtn className="hover:cursor-pointer" />
            </div>
          </div>
          <p className="text-sm lg:text-base text-greyBase mb-[30px] lg:mb-[40px]">
            Ваш отзыв успешно отправлен. Он поможет улучшить работу сервисов ZiNGiZi.
          </p>
          <div className="flex justify-end">
            <Button
              type={'black'}
              className="w-max !p-[10px_30px] !text-base !font-medium !leading-4"
              onClick={() => setIsShowModal(false)}>
              Хорошо
            </Button>
          </div>
        </div>
      </ModalWrap>
    </div>
  );
};
