import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

const ModalWrap: FC<{ isShow: boolean; children: React.ReactNode; className?: string }> = ({
  isShow = false,
  children,
  className,
}) => {
  useEffect(() => {
    if (isShow) {
      document.body.classList.add('hidden_scroll');
    }
    if (!isShow) {
      document.body.classList.remove('hidden_scroll');
    }
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  return (
    <div
      className={classNames(
        'w-full h-full fixed bottom-0 top-0 right-0 left-0 z-20 bg-greyBase15 flex items-center justify-center p-[15px]',
        className
      )}>
      {children}
    </div>
  );
};

export default ModalWrap;
