import { FC, useEffect, useState } from 'react';
import { checkEmailMask } from '../../utils/validators';
import ModalWrap from '../common/modal-wrap';
import { CloseCrossBtn } from '../icons/close-cross-btn';
import Input from '../common/input';
import { CustomSelect } from '../common/select';
import { Switch } from '../common/switch';
import Button from '../common/button';
import { timezoneList } from '../../assets/timezone';
import { IClient } from '../../store/types';

interface ICreateClientProps {
  open: boolean;
  close: () => void;
  save: (form: IFormDataClient) => void;
  clientData?: IClient;
}

export interface IFormDataClient {
  name: string;
  tz: string;
  active: boolean;
}
const defaultClient = {
  name: '',
  tz: '(GMT +03:00) Москва',
  active: true,
};

export const CreateClientForm: FC<ICreateClientProps> = ({ open, close, save, clientData }) => {
  const [formDataClient, setFormDataClient] = useState<IFormDataClient>(defaultClient);
  const [errorClient, setErrorClient] = useState('');
  const [errorTZ, setErrorTZ] = useState('');

  useEffect(() => {
    if (clientData) {
      setFormDataClient((state) => ({
        ...state,
        name: clientData.full_name,

        tz: clientData.time_zone,

        active: clientData.is_active,
      }));
    } else {
      setFormDataClient(defaultClient);
    }
  }, [clientData, open]);

  const onBlurClient = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setErrorClient('Заполните поле Клиент');
    } else {
      setErrorClient('');
    }
  };
  const handleSave = () => {
    const tzListName = timezoneList.map((i) => i.name).includes(formDataClient.tz);

    if (formDataClient.name === '' || !tzListName) {
      setErrorClient(formDataClient.name === '' ? 'Заполните поле Клиент' : '');
      setErrorTZ(!tzListName ? 'Выберите из списка' : '');

      return;
    }
    save(formDataClient);
    handleClose();
  };

  const handleClose = () => {
    setErrorClient('');

    setErrorTZ('');

    setFormDataClient(defaultClient);
    close();
  };

  return (
    <ModalWrap isShow={open} className="!p-0 md:p-[15px]">
      <div className="think-scroll flex flex-col gap-3.5 lg:gap-5 w-full md:max-w-[60%] lg:max-w-[978px] h-full md:h-auto md:max-h-[90%] md:rounded-[20px] lg:rounded-[40px] p-5 lg:p-8 bg-white">
        <div className="w-full flex justify-between items-center">
          <h2 className="font-comfortaa lg:text-2xl text-[#111026] font-bold">
            {!clientData && 'Создание клиента'}
          </h2>
          <button className="cursor-pointer" onClick={() => handleClose()}>
            <CloseCrossBtn width={20} height={20} />
          </button>
        </div>
        <div className="flex flex-col gap-3.5 lg:gap-5 ">
          <Input
            classNameLabel="text-xs text-[#585858]"
            className="!h-[40px] lg:!h-[48px] placeholder-[#111026]"
            label="Клиент (обязательное поле)"
            value={formDataClient.name}
            onChange={(e) => {
              if (e.target.value.length > 50) {
                return;
              }
              setFormDataClient({ ...formDataClient, name: e.target.value });
            }}
            placeholder="Введите ФИО или псевдоним"
            error={errorClient}
            onBlur={onBlurClient}
            showError={false}
          />

          <div className="w-full flex flex-col lg:flex-row lg:items-center gap-3.5 lg:gap-[30px]">
            <div className="w-full lg:max-w-[320px]">
              <CustomSelect
                label="Часовой пояс клиента"
                items={timezoneList.map((el) => el.name)}
                selected={formDataClient.tz}
                onChange={(a) => {
                  setFormDataClient({ ...formDataClient, tz: a });
                  setErrorTZ('');
                }}
                helper="Выберите часовой пояс клиента, чтобы время встречи отображалось правильно для него и вас."
                error={errorTZ}
                showError={false}
                classNameUlList="!max-h-[168px]"
              />
            </div>
            <div className="lg:pt-[21px]">
              <Switch
                checked={formDataClient.active}
                setChecked={(a) => {
                  setFormDataClient({ ...formDataClient, active: a });
                }}
                label="Клиент Активный"
              />
            </div>
          </div>
        </div>
        <Button
          className="!ml-auto max-w-[160px] !text-base py-[6px] px-2.5"
          type="black"
          onClick={handleSave}>
          Сохранить
        </Button>
      </div>
    </ModalWrap>
  );
};
