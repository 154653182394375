import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import AdBanner from "../common/banner/adBanner";

const Menu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuName, setMenuName] = useState('');
  const params = useLocation();
  const [, path] = params.pathname.split('/');

  const getMenuName = (path: string) => {
    switch (path) {
      case 'profile':
        return 'Профиль';
      case 'consultations':
        return 'Создать встречу';
      case 'journal':
        return 'Терапевтический журнал';
      case 'tools':
        return 'Инструменты';
      case 'tools/emotion-detector':
        return 'Детектор эмоций';
      case 'tools/metaphorical-cards':
        return 'Метафорические карты';
      case 'tools/question':
        return 'Подкинь вопрос';
      case 'tools/dpdg':
        return 'ДПДГ онлайн';
      case 'tariffs':
        return 'Тарифы';
      case 'info':
        return 'Информация';

      default:
        return 'Профиль';
    }
  };

  useEffect(() => {
    setMenuName(getMenuName(path));
  }, [params, path]);

  return (
    <div>
      <div className="flex lg:hidden h-[40px] gap-[20px]">
        <div
          className="p-[12px_30px] rounded-[6px] bg-redBase font-comfortaa font-bold text-sm text-white grow relative"
          onClick={() => setOpenMenu((state) => !state)}>
          <span>{menuName}</span>

          {openMenu && (
            <nav className="flex flex-col lg:hidden w-full text-blackBase text-sm lg:text-base absolute top-[100%] left-0 z-50 bg-grey6 lg:bg-white py-[10px] px-[15px] lg:p-0">
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Профиль
              </NavLink>
              <NavLink
                to="/consultations"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive || path === 'settings' ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Создать встречу
              </NavLink>
              <NavLink
                to="/journal"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Терапевтический журнал
              </NavLink>
              <NavLink
                to="/tools"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Инструменты
              </NavLink>
              <div className={classNames('flex flex-col items-end')}>
                <NavLink
                  to="/tools/emotion-detector"
                  className={({ isActive }) =>
                    `w-full max-w-[90%] p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                      isActive ? '!bg-goldWithOpacity50' : ''
                    }`
                  }>
                  Детектор эмоций
                </NavLink>
                <NavLink
                  to="/tools/metaphorical-cards"
                  className={({ isActive }) =>
                    `w-full max-w-[90%] p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                      isActive ? '!bg-goldWithOpacity50' : ''
                    }`
                  }>
                  Метафорические карты
                </NavLink>
                <NavLink
                  to="/tools/question"
                  className={({ isActive }) =>
                    `w-full max-w-[90%] p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                      isActive ? '!bg-goldWithOpacity50' : ''
                    }`
                  }>
                  Подкинь вопрос
                </NavLink>
                <NavLink
                  to="/tools/dpdg"
                  className={({ isActive }) =>
                    `w-full max-w-[90%] p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                      isActive ? '!bg-goldWithOpacity50' : ''
                    }`
                  }>
                  ДПДГ онлайн
                </NavLink>
              </div>

              <NavLink
                to="/tariffs"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Тарифы
              </NavLink>
              <NavLink
                to="/info"
                className={({ isActive }) =>
                  `p-[15px_30px] rounded-[6px] lg:rounded-[10px] ${
                    isActive ? '!bg-goldWithOpacity50' : ''
                  }`
                }>
                Информация
              </NavLink>
            </nav>
          )}
        </div>
        <div onClick={() => setOpenMenu((state) => !state)}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="6" fill="#E0E0E8" />
            <path
              d="M15.5003 16.0134H24.5003C24.5914 16.0137 24.6807 16.0388 24.7586 16.0861C24.8365 16.1333 24.9001 16.201 24.9424 16.2817C24.9847 16.3623 25.0042 16.4531 24.9988 16.544C24.9934 16.635 24.9634 16.7227 24.9118 16.7979L20.4118 23.2979C20.2253 23.5674 19.7763 23.5674 19.5893 23.2979L15.0893 16.7979C15.0372 16.7229 15.0067 16.6351 15.001 16.544C14.9953 16.4529 15.0147 16.3619 15.057 16.2811C15.0994 16.2002 15.1631 16.1325 15.2413 16.0853C15.3194 16.0381 15.409 16.0132 15.5003 16.0134Z"
              fill="#111026"
            />
          </svg>
        </div>
      </div>

      <nav className="hidden lg:flex lg:w-[290px] flex-col lg:gap-[15px] text-blackBase text-sm lg:text-base mb-2.5">
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive ? '!bg-redBase text-white' : ''
            }`
          }>
          Профиль
        </NavLink>
        <NavLink
          to="/consultations"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive || path === 'settings' ? '!bg-redBase text-white' : ''
            }`
          }>
          Создать встречу
        </NavLink>
        <NavLink
          to="/journal"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive ? '!bg-redBase text-white' : ''
            }`
          }>
          Терапевтический журнал
        </NavLink>
        <NavLink
          to="/tools"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive ? '!bg-redBase text-white' : ''
            }`
          }>
          Инструменты
        </NavLink>
        <div
          className={classNames('hidden flex-col items-end gap-[15px]', {
            '!flex': path.includes('tools'),
          })}>
          <NavLink
            to="/tools/emotion-detector"
            className={({ isActive }) =>
              `w-[95%] max-w-[252px] h-[40px] p-[8px_20px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
                isActive ? '!bg-redBase text-white' : ''
              }`
            }>
            Детектор эмоций
          </NavLink>
          <NavLink
            to="/tools/metaphorical-cards"
            className={({ isActive }) =>
              `w-[95%] max-w-[252px] h-[40px] p-[8px_20px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
                isActive ? '!bg-redBase text-white' : ''
              }`
            }>
            Метафорические карты
          </NavLink>
          <NavLink
            to="/tools/question"
            className={({ isActive }) =>
              `w-[95%] max-w-[252px] h-[40px] p-[8px_20px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
                isActive ? '!bg-redBase text-white' : ''
              }`
            }>
            Подкинь вопрос
          </NavLink>
          <NavLink
            to="/tools/dpdg"
            className={({ isActive }) =>
              `w-[95%] max-w-[252px] h-[40px] p-[8px_20px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
                isActive ? '!bg-redBase text-white' : ''
              }`
            }>
            ДПДГ онлайн
          </NavLink>
        </div>

        <NavLink
          to="/tariffs"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive ? '!bg-redBase text-white' : ''
            }`
          }>
          Тарифы
        </NavLink>
        <NavLink
          to="/info"
          className={({ isActive }) =>
            `p-[15px_30px] rounded-[6px] lg:rounded-[10px] lg:bg-greyWithOpacity30 hover:bg-goldWithOpacity50 ${
              isActive ? '!bg-redBase text-white' : ''
            }`
          }>
          Информация
        </NavLink>
        {/* <div className="flex w-full max-w-[240px] h-[400px] mt-4 self-center items-center justify-center rounded-[20px] lg:rounded-[30px]"> */}
          {/* 3 Реклама */}
          <AdBanner />
          
        {/* </div> */}
      </nav>
    </div>
  );
};

export default Menu;
