import React, { FC, useEffect, useRef, useState } from 'react';
import { ArrowLeft } from '../icons/arrow-left';
import { useLoginFinishMutation, useLoginMutation } from '../../services/auth.api';
import Timer from '../common/timer/timer';
import Loader from '../common/loader';
import { useLazyMeQuery } from '../../services/users.api';
import { setCredentials, setUserData } from '../../store/auth.slice';
import { useAppDispatch } from '../../hooks/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface IProps {
  onBackToAuth: () => void;
  email: string;
  confirmCodex?: boolean;
}

const ConfirmAuth: FC<IProps> = ({ confirmCodex = false, onBackToAuth, email }) => {
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [authCode, setAuthCode] = useState<(string | null)[]>([null, null, null, null, null]);
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [error, setError] = useState('');
  const [login] = useLoginMutation();
  const [loginFinish, { isLoading }] = useLoginFinishMutation();
  const [me] = useLazyMeQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      authCode &&
      authCode.filter((el) => el !== null && el !== '' && +el >= 0 && +el <= 9).length === 5
    ) {
      onAuthFinish();
    }
    // eslint-disable-next-line
  }, [authCode]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    console.log(e.key, idx);
    if (e.key === 'Backspace') {
      if (authCode[idx] !== '') {
        const newHms = [...authCode];
        newHms[idx] = '';
        setAuthCode(newHms);
        return;
      }

      if (idx > 0) {
        itemsRef?.current[idx - 1]?.focus();
      }
    }
  };
  const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setIsErrorCode(false);
    setError('');
    const [, codeFieldIndex] = event.target.name.split('-');
    if (event.target.value.length === 5) {
      setAuthCode(event.target.value.split(''));
      itemsRef?.current?.[+codeFieldIndex]?.blur();
    }
    const isNum = event.target.value.match(/^[0-9]{0,1}$/i);

    if (!isNum) {
      return;
    }

    setAuthCode((state) => [...state.slice(0, idx), event.target.value, ...state.slice(idx + 1)]);

    if (+codeFieldIndex + 1 < 5 && event.target.value.length) {
      itemsRef?.current?.[+codeFieldIndex + 1]?.focus();
    } else if (event.target.value) {
      itemsRef?.current?.[+codeFieldIndex]?.blur();
    }
    // if (+codeFieldIndex > 0 && event.target.value === '') {
    //   itemsRef?.current?.[+codeFieldIndex - 1]?.focus();
    // }
  };
  const onAuthFinish = async () => {
    if (authCode.filter((el) => el !== null && el !== '' && +el >= 0 && +el <= 9).length !== 5) {
      setIsErrorCode(true);
      setError('Введите код');
      return;
    }

    try {
      const tokenData = await loginFinish({ email, authCode: authCode.join('') }).unwrap();
      dispatch(setCredentials(tokenData));
      const userData = await me().unwrap();
      dispatch(setUserData(userData));
      navigate('/profile', { state: { previousLocationPathname: location.pathname } });
    } catch (e: any) {
      console.log(e);
      if (e.status === 422) {
        setIsErrorCode(true);
        setError(e.data.detail);
      }
    }
  };

  const onRefreshAuthCode = async () => {
    await login({ email }).unwrap();
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="w-full max-w-[420px] sm:max-w-[550px] max-h-[90%] overflow-y-auto rounded-[30px] bg-white p-[10px] md:p-[50px] small:p-[10px]">
        <div className="flex items-center font-comfortaa font-bold text-center text-2xl md:text-4xl small:text-2xl mb-2.5">
          <button onClick={onBackToAuth} className="group">
            <ArrowLeft className="w-[14px] h-[18px] md:w-[21px] md:h-[27px] fill-blackBase group-hover:fill-redBase" />
          </button>

          <span className="grow text-center">Подтверждение</span>
        </div>
        <div className="text-center sm:px-[30px] text-sm md:text-base text-greyBase mb-7 small:mb-5 md:mb-10">
          На почту {email} был отправлен код подтверждения, который необходимо ввести для того,
          чтобы войти в Личный кабинет.
        </div>
        <div className="mb-7 md:mb-10 small:mb-5">
          <div className="flex justify-center gap-2.5 mb-7 small:mb-5">
            {Array.from({ length: 5 }).map((el, idx) => {
              return (
                <div key={idx} className="w-[58px] h-[58px] flex gap-2.5 md:w-[82px] md:h-[82px]">
                  <input
                    ref={(ref) => itemsRef.current.push(ref)}
                    type="tel"
                    name={`code-${idx}`}
                    value={authCode?.[idx] || ''}
                    onChange={(event) => codeChangeHandler(event, idx)}
                    onKeyDown={(e) => handleKeyDown(e, idx)}
                    className={classNames(
                      'w-full h-full text-2xl md:text-5xl border border-greyBase rounded-[10px] text-blackBase text-center hide-input-number-arrow',
                      {
                        'border-redBase': isErrorCode,
                      }
                    )}
                  />
                </div>
              );
            })}
          </div>

          <Timer minutes={1} seconds={0} onRefreshAuthCode={onRefreshAuthCode} />
        </div>

        {error && <div className="text-redBase mb-2.5">{error}</div>}

        <div className="mb-7 small:mb-5">
          <button
            className="font-comfortaa font-bold flex justify-center p-[15px] bg-redBase text-white md:text-xl w-full rounded-[10px] hover:bg-redBaseWithBlur20"
            onClick={onAuthFinish}>
            Подтвердить
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmAuth;
