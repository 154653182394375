import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

export const ConnectionQualityPanel = () => {
  const [quality, setQuality] = useState<
    'excellent' | 'good' | 'unstable' | 'disconnected' | null
  >(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (event.type === 'connectionQuality') {
        setQuality(event.detail.quality);
      }
    };

    window.addEventListener('connectionQuality', handler);
    return () => window.removeEventListener('connectionQuality', handler);
  }, []);

  if (quality !== 'unstable') return null;

  return (
    <div
      className={classNames(
        'fixed top-[45px] left-[25px]  z-[100] px-4 py-2 text-sm text-white blackBase rounded-md pointer-events-none'
      )}>
      ⚠️ Нестабильное соединение
    </div>
  );
};