import { FC, useEffect, useState } from 'react';
import { PsychologEnd } from './psycholog';
import { ClientEnd } from './client';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../store/auth.slice';
import { IConsultation } from '../../store/types';
import { useLazyGetConsultationsQuery } from '../../services/users.api';
import { useParams } from 'react-router-dom';

export const EndMeet: FC = () => {
  const [isPsyhologist, setIsPsyhologist] = useState(false);
  const [consultation, setConsultation] = useState<IConsultation | null>(null);
  const [getConsultations, { data: consultations }] = useLazyGetConsultationsQuery();
  const userData = useAppSelector(selectUser);
  const params = useParams();
  const roomName = params.id;

  useEffect(() => {
    getConsultations({ code: String(roomName) });
  }, []);

  useEffect(() => {
    if (consultations) {
      setConsultation(consultations[0]);
    }
  }, [consultations]);

  useEffect(() => {
    if (!userData || !consultation) return;
    if (userData.id === consultation.psychologist.id) {
      setIsPsyhologist(true);
    }
  }, [userData, consultation]);
  return <>{isPsyhologist ? <PsychologEnd consultation={consultation} /> : <ClientEnd />}</>;
};
