import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import Input from '../input';
import { Arrow } from '../../icons/arrow';
import { QuestionsCircleIcon } from '../../icons/question-circle';

type SelectProps = {
  label?: string;
  items: string[];
  className?: string;
  classNameInput?: string;
  classNameUlList?: string;
  placeholder?: string;
  clickToOpenClose?: boolean;
  onChange?: (a: string) => void;
  selected?: string;
  error?: string;
  showError?: boolean;
  required?: boolean;
  helper?: string;
  disabled?: boolean;
  isChange?: boolean;
  readOnly?: boolean;
};

export const CustomSelect: FC<SelectProps> = ({
  label,
  items,
  className,
  classNameInput,
  classNameUlList,
  placeholder,
  clickToOpenClose = false,
  onChange,
  selected,
  error = '',
  showError = true,
  required = false,
  helper = '',
  disabled = false,
  isChange = true,
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const handleChange = (val: string) => {
    setSearchVal('');
    onChange && onChange(val);
    setOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);
  return (
    <div className={classNames('w-full flex flex-col gap-[5px] relative', className)} ref={ref}>
      {label && (
        <div className={classNames('flex items-center gap-[10px]', open ? 'z-[12]' : '')}>
          <label className="text-xs text-[#585858] whitespace-nowrap">
            {label} {required ? ' *' : ''}
          </label>
          {helper && (
            <div className="w-full max-w-[666px] relative ">
              <span
                className="w-[15px] block"
                onMouseMove={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <QuestionsCircleIcon width={15} height={15} />
              </span>

              {hover && (
                <div className="absolute z-[10] min-w-[100%] md:min-w-[200px] rounded-[4px] bg-white text-sm text-[#585858] left-[20px] -bottom-4 md:bottom-1.5 py-[6px] px-[8px] drop-shadow-md">
                  {helper}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <Input
        readOnly={readOnly}
        className={classNames(
          '!select-none',
          {
            'cursor-pointer': readOnly,
          },
          classNameInput
        )}
        classNameWrap={open ? 'z-[11]' : ''}
        placeholder={placeholder}
        value={selected || searchVal || undefined}
        disabled={disabled}
        onChange={(e) => {
          if (!isChange) {
            return;
          }
          onChange && onChange(e.target.value);
          setSearchVal(e.target.value);
        }}
        onClick={() => {
          if (clickToOpenClose) {
            setOpen((v) => !v);
          }
        }}
        onFocus={() => {
          if (!clickToOpenClose) {
            setOpen(true);
          }
        }}
        icon={{
          type: 'suffix',
          icon: (
            <>
              {items.filter((el) =>
                searchVal ? el.toLowerCase().includes(searchVal.toLowerCase()) : el
              ).length > 0 && (
                <span onClick={() => setOpen(!open)}>
                  <Arrow
                    className={classNames('w-[12px] lg:h-[12px] transition-all cursor-pointer', {
                      ['rotate-180']: open,
                    })}
                  />
                </span>
              )}
            </>
          ),
        }}
        error={error}
        showError={false}
      />
      {open &&
        items.filter((el) => (searchVal ? el.toLowerCase().includes(searchVal.toLowerCase()) : el))
          .length > 0 && (
          <ul
            className={classNames(
              'w-full z-10 absolute top-[calc(100%_-_10px)] flex flex-col pt-4  px-5 bg-[#FFFFFF] border list-none border-[#585858] rounded-[0px_0px_10px_10px] max-h-[164px] h-auto overflow-y-scroll no-scrollbar',
              classNameUlList
            )}>
            {items
              .filter((el) => (searchVal ? el.toLowerCase().includes(searchVal.toLowerCase()) : el))
              .map((el, idx) => (
                <li
                  onClick={() => handleChange(el)}
                  key={idx}
                  className={classNames(
                    'w-full  border-b cursor-pointer hover:bg-[#EFE0AF] text-[#111026] hover:rounded-[6px] border-[#CDCDCD]  p-[12px] break-words last:border-none',
                    {
                      'bg-[#DEC05E] rounded-[6px] text-[#776743]': selected === el,
                    }
                  )}>
                  {el}
                </li>
              ))}
          </ul>
        )}
      <div className="text-sm text-redBase absolute top-[110%]">{showError && error}</div>
    </div>
  );
};
