import React, { useEffect } from "react";

declare global {
  interface Window {
    Ya: any;
    yaContextCb: any[];
  }
}

const AdBanner = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://yandex.ru/ads/system/context.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.yaContextCb) {
        window.yaContextCb.push(() => {
          if (window.Ya) {
            window.Ya.Context.AdvManager.render({
              blockId: "R-A-14111633-5",
              renderTo: "yandex_rtb_R-A-14111633-5",
            });
          }
        });
      }
    };
  }, []);

  return (
    <div
      id="yandex_rtb_R-A-14111633-5"
      className="flex w-full max-w-[240px] h-[400px] mt-4 self-center items-center justify-center rounded-[20px] lg:rounded-[30px]"
    ></div>
  );
};

export default AdBanner;