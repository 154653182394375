import React from 'react';
import classNames from 'classnames';

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>;
  joined: boolean;
  isVideoMuted: boolean;
  isOpenVideoMe: boolean;
  isDpdg: boolean;
  setIsOpenVideoMe: (val: boolean) => void;
};

export const LocalVideo: React.FC<Props> = ({
  videoRef,
  joined,
  isVideoMuted,
  isOpenVideoMe,
  isDpdg,
  setIsOpenVideoMe,
}) => {
  return (
    <div
      className={classNames(
        'w-[160px] h-[100px] lg:w-[280px] lg:h-[180px] max-w-[280px] h-full max-h-[120px] lg:max-h-[180px]',
        {
          '!relative !w-full !h-full lg:!w-[280px] !right-0 !top-0': isDpdg && isOpenVideoMe,
          '!w-full !h-auto bg-[#3B3A3F] p-[10px] rounded-[15px] !relative !right-0 !top-[0]':
            !isOpenVideoMe,
          'absolute z-[11] top-[15px] right-[15px] !h-full !max-h-[180px]': !isDpdg,
        }
      )}>
      {isDpdg && (
        <svg
          className={classNames(
            'absolute top-[9px] lg:top-[15px] right-[15px] z-[111] cursor-pointer duration-300',
            {
              'rotate-[180deg]': !isOpenVideoMe,
            }
          )}
          onClick={() => setIsOpenVideoMe(!isOpenVideoMe)}
          width="24"
          height="24"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.39 5.71C9.52 5.8 9.68 5.99 9.8 6.1L10.76 7.01L13.52 9.65L14.41 10.5C14.58 10.67 14.75 10.84 14.94 11C14.71 11.19 14.51 11.41 14.3 11.61L13.08 12.77L9.47 16.22L9.39 16.29C9.29 16.17 9.17 16.06 9.07 15.95C8.86 15.73 8.65 15.51 8.44 15.29C8.95 14.83 9.44 14.34 9.94 13.86L12.94 11C12.64 10.71 12.33 10.42 12.03 10.13L10.35 8.53L9.11 7.35C8.89 7.13 8.67 6.91 8.44 6.71C8.76 6.38 9.07 6.05 9.39 5.71Z"
            fill="white"
          />
        </svg>
      )}
      <video
        id="localvideo"
        ref={videoRef}
        className={classNames(
          'absolute top-0 left-0 bg-[#2b2b35] hide-video-controls hidden w-full h-full object-cover rounded-[15px]',
          {
            '!block': joined && !isVideoMuted,
            '!opacity-0 !h-0': !isOpenVideoMe,
          }
        )}
        autoPlay
        muted
        playsInline
      />
      <div
        className={classNames(
          'w-full h-full hidden flex-col bg-[#2b2b35] items-center justify-center rounded-[15px]',
          {
            '!flex': !joined || isVideoMuted,
            '!opacity-0 !h-0': !isOpenVideoMe,
          }
        )}
      />
      <div
        className={classNames(
          'absolute bottom-[15px] left-[15px] w-auto flex items-center gap-x-[10px] lg:gap-x-[15px] p-[3px_10px] lg:p-[6px_15px] bg-greyWithOpacity1_50 rounded-[5px]',
          {
            'w-max relative !left-0 !bottom-0': !isOpenVideoMe,
          }
        )}>
        <div className="text-xs lg:text-sm text-white">Вы</div>
      </div>
    </div>
  );
};